<template>
    <!-- 安全设置 移动端 -->
    <div class="center">
        <!--  @click="openPhonePop"  v-if="contact_type==1"-->
        <div class="row" @click="bindTelegram">
            <div class="left">
                {{$t('common.telegram')}}
            </div>
            <div class="right jump">
                {{telegram?'@'+telegram:telegram}}
				<i class="iconfont2 icon-right_arrow"></i>
            </div>
        </div>
        <div class="row" @click="showEmail = true">
            <div class="left">
                {{$t('common.email')}}
            </div>
            <div class="right jump">
                {{email}}
				<i class="iconfont2 icon-right_arrow"></i>
            </div>
        </div>
        <div class="row" @click="openPwdPop">
            <div class="left">
                {{$t('common.pwd')}}
            </div>
            <div class="right jump">
				<div class="value">**************</div>
				<i class="iconfont2 icon-right_arrow"></i>
            </div>
        </div>
        <div class="row">
            <div class="left">
                {{$t('user.safeLogin')}}
            </div>
            <div class="right">
                <el-switch
                @change="switchChange"
                active-value="1"
                inactive-value="0"
                v-model="safeType"
                active-color="#E65B1B"
                inactive-color="#e9e9e9">
                </el-switch>
            </div>
        </div>
        <!-- <div class="row">
            <div class="left">
                {{$t('user.ipVer')}}
                <span>({{$t('user.ipRecommend')}})</span>
            </div>
            <div class="right">
                <el-switch
                @change="switchChange2"
                active-value="1"
                inactive-value="0"
                v-model="ipVer"
                active-color="#E65B1B"
                inactive-color="#e9e9e9">
                </el-switch>
            </div>
        </div> -->
        <!-- 弹窗 -->
		<!-- 修改手机号 -->
        <!-- <van-action-sheet 
        v-model="showPhone" 
        :title="$t('user.modifyNum')"
        :closeable="false"
        @closed="handleCancel">
            <div class="add_form">
                <div class="form">
                    <div class="item">
                        <div class="title">{{$t('common.newPhone')}}</div>
                        <div class="text">
                            <input
                                class="inp"
                                :placeholder="$t('common.input')"
                                type="number"
                                v-model="form.tel"
                            />
                        </div>
                    </div>
                    <div class="item disable">
                        <div class="title">{{$t('common.email')}}</div>
                        <div class="text">
                            <input
                                class="inp"
                                :placeholder="$t('common.bind')+$t('common.email')"
                                disabled
                                v-model="email"
                            />
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">
                            {{$t('common.verification')}}
                        </div>
                        <div class="text">
                            <div class="inp_code">
                                <input
                                    class="inp"
                                    :placeholder="$t('common.inputVerification')"
                                    type="text"
                                    v-model="form.sms_code"
                                />
                                <div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
                                <div v-else class="g_btn btn" @click="handleSendCode">
                                    <template v-if="firstSend">{{$t('common.sendVerification')}}</template>
                                    <template v-else>{{$t('common.resend')}}</template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="foot">
                    <div class="btn" @click="handleCancel">{{$t('common.cancel')}}</div>
                    <div class="btn submit" @click="handleConfirm">{{$t('common.confirm2')}}</div>
                </div>
            </div>
        </van-action-sheet> -->

        <!-- 电报解绑 -->
        <van-action-sheet 
        class="remove_bind"
        v-model="showRemovePop" 
        :closeable="false"
        @closed="handleCancel">
            <button class="remove_bind_btn" @click="removeTelegram">
                {{$t('common.removeBind')}}
            </button>
        </van-action-sheet>

        <!-- 修改邮箱 -->
        <van-action-sheet 
        v-model="showEmail" 
        :title="$t('user.changeEmail')"
        :closeable="false"
        @closed="handleCancel">
            <div class="add_form">
                <div class="form">
                    <div class="item">
                        <div class="title">{{$t('common.newEmail')}}</div>
                        <div class="text">
                            <input
                                class="inp"
                                :placeholder="$t('common.input')"
                                v-model="form.email"
                            />
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">{{$t('common.verification')}}</div>
                        <div class="text">
                            <div class="inp_code">
                                <input
                                    class="inp"
                                    :placeholder="$t('common.inputVerification')"
                                    type="text"
                                    v-model="form.sms_code"
                                />
                                <div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
                                <div v-else class="g_btn btn" @click="handleSendCode('email')">
                                    <template v-if="firstSend">{{$t('common.sendVerification')}}</template>
                                    <template v-else>{{$t('common.resend')}}</template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="foot">
                    <div class="btn" @click="handleCancel">{{$t('common.cancel')}}</div>
                    <div class="btn submit" @click="editEmail">{{$t('common.confirm2')}}</div>
                </div>
            </div>
        </van-action-sheet>

        <!-- 修改密码 -->
        <van-action-sheet 
        v-model="showPassw" 
        :title="$t('user.changePwd')"
        :closeable="false"
        @closed="handleCancel">
            <div class="add_form">
                <div class="form">
                    <div class="item disable">
                        <div class="title">{{$t('common.email')}}</div>
                        <div class="text">
                            <input
                                class="inp"
                                :placeholder="$t('common.input')"
                                disabled
                                v-model="email"
                            />
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">{{$t('common.verification')}}</div>
                        <div class="text">
                            <div class="inp_code">
                                <input
                                    class="inp"
                                    :placeholder="$t('common.inputVerification')"
                                    type="text"
                                    v-model="form.sms_code"
                                />
                                <div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
                                <div v-else class="g_btn btn" @click="handleSendCode">
                                    <template v-if="firstSend">{{$t('common.sendVerification')}}</template>
                                    <template v-else>{{$t('common.resend')}}</template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">{{$t('common.newPwd')}}</div>
                        <div class="text">
                            <input
                                class="inp"
                                :placeholder="$t('common.input')"
                                type="password"
                                v-model="form.password1"
                            />
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">{{$t('common.confirmNewPwd')}}</div>
                        <div class="text">
                            <input
                                class="inp"
                                :placeholder="$t('common.input')"
                                type="password"
                                v-model="form.password2"
                            />
                        </div>
                    </div>
                </div>
                <div class="foot">
                    <div class="btn" @click="handleCancel">{{$t('common.cancel')}}</div>
                    <div class="btn submit" @click="handleSubmit">{{$t('common.confirm2')}}</div>
                </div>
            </div>
        </van-action-sheet>
    </div>
</template>
<style lang="stylus" scoped>
	@import 'center.styl';
</style>
<script>
import { Loading } from 'element-ui';
import { mapGetters } from 'vuex';
// api
import {
    // updateTel,
	cancelTelegramBind,
    updatePassword,sendEmail,updateEmail,setSafeLogin,setIPCheck 
} from '@/api/user'
var timer;
export default {
    data(){
        return{
            // showPhone: false,
            showRemovePop:false,
            isUnbinding:false,//解绑是否是点击状态
            showPassw: false,
			showEmail: false,

            codeSending: false,
            timeCount: 60,
            firstSend: true,
			
            form: {},
			safeType:'0',//安全登录 0 1
			ipVer:'1',//IP验证 0 1
        }
    },
    created(){
        // 开关默认值
        this.safeType=this.safe_login
        this.ipVer=this.ip_check
        
        if(this.isMobile==1){
			this.getInfo();
        }else{
            this.$router.push({
                path:'/user'
            })
        }
    },
    computed:{
        ...mapGetters(['account',
        // 'tel',
        'telegram',
        'email','isMobile','safe_login','ip_check']),
        // contact_type(){
        //     // var isTelegram;
        //     // if(!this.tel){
        //     //     isTelegram=0
        //     // }else{
        //     //     var tel=this.tel.slice(-11)
        //     //     var reg =/^0?1[3|4|5|6|7|8][0-9]\d{8}$/;
        //     //     isTelegram=reg.test(tel)?0:1
        //     // }
        //     var reg =/^\d+$/;
        //     var isTelegram=reg.test(this.tel)||!this.tel?0:1
        //     return isTelegram;
        // },
    },
    methods:{
        // 获取详情
        getInfo(){
            const loading = Loading.service({
                target: '.main',
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: 'apploading',
            });
            this.$store.dispatch('user/getInfo').then(res=>{
                this.safeType=this.safe_login
				this.ipVer=this.ip_check
                loading.close();
            }).catch(err=>{
                loading.close();
            })
        },
        handleSendCode(type) {
            if(!this.codeSending){
                // 发送验证码
                var email;
                if(type=='email'){
                    // 修改邮箱
                    var emailReg=/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
                    // /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                    if(!this.form.email){
                        this.$message({
                            message: this.$t('tip.emailEmpty')+'！',
                            type: 'error',
                            center: true,
                            customClass: 'shotMsg',
                        });
                        return
                    }else if(!emailReg.test(this.form.email) ){
                        this.$message({
                            message: this.$t('tip.emailVer')+'！',
                            type: 'error',
                            center: true,
                            customClass: 'shotMsg',
                        });
                        return
                    }else if(this.form.email==this.email){
                        this.$message({
                            message: this.$t('tip.emailRepeat'),
                            type: 'error',
                            center: true,
                            customClass: 'shotMsg',
                        });
                        return
                    }else{
                        email=this.form.email
                        // 发送验证码
                        this.timeCount = 60;
                        this.codeSending = true;
                        let that = this;
                        this.firstSend = false;
                        clearInterval(timer);
                        timer = setInterval(() => {
                            if(this.timeCount >= 1) {
                                that.timeCount -= 1;
                            } else {
                                clearInterval(timer);
                                this.codeSending = false;
                            }
                        }, 1000);

                        sendEmail({
                            account:this.account,
                            email,
                            type:2,
                        })
                    }
                }else{
                    // 修改手机、密码
                    email=this.email
                    // 发送验证码
                    this.timeCount = 60;
                    this.codeSending = true;
                    let that = this;
                    this.firstSend = false;
                    clearInterval(timer);
                    timer = setInterval(() => {
                        if(this.timeCount >= 1) {
                            that.timeCount -= 1;
                        } else {
                            clearInterval(timer);
                            this.codeSending = false;
                        }
                    }, 1000);
                
                    sendEmail({
                        account:this.account,
                        email,
                        type:1,
                    })
                }
            }
        },
        handleCancel() {
            // this.showPhone = false;
            this.showRemovePop = false;
			this.showEmail = false;
            this.showPassw = false;
            this.form = {};
            
            clearInterval(timer);
            this.codeSending = false;
            this.firstSend = true;
        },
        // 打开修改手机弹窗
        // openPhonePop(){
        //     if(!this.email){
        //         this.$confirm(this.$t('tip.notBindEmail'), this.$t('tip.tips'), {
        //             confirmButtonText: this.$t('common.toBind'),
        //             cancelButtonText: this.$t('common.cancel'),
        //             center: true,
        //         }).then(() => {
        //             this.showEmail=true
        //         }).catch(() => {
        //             // 取消
        //         });
        //     }else{
        //         this.showPhone = true;
        //     }
        // },
		// // 修改手机号提交
        // handleConfirm() {
        //     var phoneReg=/^1[3456789]\d{9}$/;
        //     if(!this.form.tel){
        //         this.$message({
        //             message: this.$t('tip.phoneEmpty')+'！',
        //             type: 'error',
        //             center: true,
        //             customClass: 'shotMsg',
        //         });
        //     }else if(!phoneReg.test(this.form.tel) ){
        //         this.$message({
        //             message: this.$t('tip.phoneVer')+'！',
        //             type: 'error',
        //             center: true,
        //             customClass: 'shotMsg',
        //         });
        //     }else if(!this.form.sms_code){
        //         this.$message({
        //             message: this.$t('tip.codeEmpty')+'！',
        //             type: 'error',
        //             center: true,
        //             customClass: 'shotMsg',
        //         });
        //     }else{
        //         updateTel({
        //             account:this.account,
        //             tel:this.form.tel,
        //             sms_code:this.form.sms_code,
        //             type:!!this.tel? 1:0,
        //         }).then(res=>{
        //             if(res.data==1){
        //                 // 修改成功
        //                 this.$message({
        //                     message: this.$t('tip.editSuc'),
        //                     type: 'success',
        //                     center: true,
        //                     customClass: 'shotMsg',
        //                 });
        //                 this.showPhone = false;
        //                 this.form = {};
                        
        //                 clearInterval(timer);
        //                 this.codeSending = false;
        //                 this.firstSend = true;
        //             }else{
        //                 // 修改失败
        //                 this.$message({
        //                     message: this.$t('tip.editErr'),
        //                     type: 'error',
        //                     center: true,
        //                     customClass: 'shotMsg',
        //                 });
        //             }
        //         })
        //     }
        // },

        // 绑定电报
        bindTelegram(){
            if(this.telegram){
                // 弹窗 是否解绑
                this.showRemovePop=true;
            }else{
                // window.open('https://t.me/light_helper_bot')
                window.location.href='https://t.me/light_helper_bot'
            }
        },
        // 解除绑定
        removeTelegram(){
            if(!this.isUnbinding){
                this.isUnbinding=true
                cancelTelegramBind({
                    account:this.account
                }).then(res=>{
                    if(res==1){
                        this.showRemovePop=false
                        this.$message({
                            message: this.$t('tip.remove'),
                            type: 'success',
                            center: true,
                            customClass: 'shotMsg',
                        });
                        this.getInfo();
                    }
                    this.isUnbinding=false
                }).catch(err=>{
                    this.isUnbinding=false
                })
            }
        },
        // 修改邮箱提交
        editEmail(){
            var emailReg=/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
            // /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            if(!this.form.email){
                this.$message({
                    message: this.$t('tip.emailEmpty')+'！',
                    type: 'error',
                    center: true,
                    customClass: 'shotMsg',
                });
            }else if(!emailReg.test(this.form.email) ){
                this.$message({
                    message: this.$t('tip.emailVer')+'！',
                    type: 'error',
                    center: true,
                    customClass: 'shotMsg',
                });
            }else if(!this.form.sms_code){
                this.$message({
                    message: this.$t('tip.codeEmpty')+'！',
                    type: 'error',
                    center: true,
                    customClass: 'shotMsg',
                });
            }else{
                updateEmail({
                    account:this.account,
                    email:this.form.email,
                    sms_code:this.form.sms_code,
                    type:!!this.email? 1:0,
                }).then(res=>{
                    if(res==1){
                        this.getInfo()
                        // 修改成功
                        this.$message({
                            message: this.$t('tip.editSuc'),
                            type: 'success',
                            center: true,
                            customClass: 'shotMsg',
                        });
                        this.showEmail = false;
                        this.form = {};
                        
                        clearInterval(timer);
                        this.codeSending = false;
                        this.firstSend = true;
                    }else{
                        // 修改失败
                        this.$message({
                            message: this.$t('tip.editErr'),
                            type: 'error',
                            center: true,
                            customClass: 'shotMsg',
                        });
                    }
                })
            }
        },
        // 打开修改密码弹窗
        openPwdPop(){
            if(!this.email){
                this.$confirm(this.$t('tip.notBindEmail'), this.$t('tip.tips'), {
                    confirmButtonText: this.$t('common.toBind'),
                    cancelButtonText: this.$t('common.cancel'),
                    center: true,
                }).then(() => {
                    this.showEmail=true
                }).catch(() => {
                    // 取消
                });
            }else{
                this.showPassw = true
            }
        },
        // 修改密码提交
        handleSubmit() {
            // var phoneReg=/^1[3456789]\d{9}$/;
            var reg = /[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/;
            var emailReg=/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
            // /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            if(!this.form.email){
                this.$message({
                    message: this.$t('tip.emailEmpty')+'！',
                    type: 'error',
                    center: true,
                    customClass: 'shotMsg',
                });
            }else if(!emailReg.test(this.form.email) ){
                this.$message({
                    message: this.$t('tip.emailVer')+'！',
                    type: 'error',
                    center: true,
                    customClass: 'shotMsg',
                });
            }else if(!this.form.sms_code){
                this.$message({
                    message: this.$t('tip.codeEmpty')+'！',
                    type: 'error',
                    center: true,
                    customClass: 'shotMsg',
                });
            }else if(!this.form.password1){
                this.$message({
                    message: this.$t('tip.pwdEmpty')+'！',
                    type: 'error',
                    center: true,
                    customClass: 'shotMsg',
                });
            }else if(!reg.test(this.form.password1) || this.form.password1.length < 6){
                Message({
                    message: this.$t('tip.pwdVer')+'！',
                    type: 'error',
                    duration: 3 * 1000
                });
            }else if(this.form.password1!=this.form.password2){
                this.$message({
                    message: this.$t('tip.pwd2')+'！',
                    type: 'error',
                    center: true,
                    customClass: 'shotMsg',
                });
            }else{
                updatePassword({
                    account:this.account,
                    password:this.form.password2,
                    sms_code:this.form.sms_code,
                    type:1,
                }).then(res=>{
                    if(res==1){
                        // 修改成功
                        this.$message({
                            message: this.$t('tip.editSuc'),
                            type: 'success',
                            center: true,
                            customClass: 'shotMsg',
                        });
                        
                        this.showPassw = false;
                        this.form = {};
                        
                        clearInterval(timer);
                        this.codeSending = false;
                        this.firstSend = true;
                    }else{
                        // 修改失败
                        this.$message({
                            message: this.$t('tip.editErr'),
                            type: 'error',
                            center: true,
                            customClass: 'shotMsg',
                        });
                    }
                })
            }
        },
        // 安全登录开关
        switchChange(){
            setSafeLogin({
                account:this.account,
                type:this.safeType,
            }).then(res=>{
                this.getInfo()
                // 修改成功
                this.$message({
                    message: this.$t('tip.operationSuc'),
                    type: 'success',
                    center: true,
                    customClass: 'shotMsg',
                });
            }).catch(err=>{
                this.safeType=this.safeType=='1'?'0':'1'
            })
        },
        // ip验证开关
        switchChange2(){
			if(this.ipVer==0){
                this.$confirm(this.$t('tip.ipClose')+'<div style="color:#E65B1B">'+this.$t('tip.ipCloseTip')+'</div>', this.$t('tip.tips'), {
                    confirmButtonText: this.$t('common.confirm'),
                    cancelButtonText: this.$t('common.cancel'),
                    center: true,
                    showClose: false,
                    customClass: 'deleteOk',
                    dangerouslyUseHTMLString: true
                }).then(() => {
                    setIPCheck({
                        account:this.account,
                        type:this.ipVer,
                    }).then(res=>{
                        this.getInfo()
                        // 修改成功
                        this.$message({
                            message: this.$t('tip.operationSuc'),
                            type: 'success',
                            center: true,
                            customClass: 'shotMsg',
                        });
                    }).catch(err=>{
                        this.ipVer=this.ipVer=='1'?'0':'1'
                    })
                }).catch(err=>{
                    this.ipVer=this.ipVer=='1'?'0':'1'
                })
            }else{
                setIPCheck({
                    account:this.account,
                    type:this.ipVer,
                }).then(res=>{
                    this.getInfo()
                    // 修改成功
                    this.$message({
                        message: this.$t('tip.operationSuc'),
                        type: 'success',
                        center: true,
                        customClass: 'shotMsg',
                    });
                }).catch(err=>{
                    this.ipVer=this.ipVer=='1'?'0':'1'
                })
            }
        },
    },
    beforeDestroy() {
        if(timer){
            clearInterval(timer)
            timer=''
        }
    }
}
</script>
